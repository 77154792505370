import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    QueryList,
    ViewChildren,
    ViewEncapsulation
} from '@angular/core';
import {ExternalContractsService} from '../../services/external-contracts.service';
import {Customer, ExternalContractData, INSTRUCTION_TRANSFER_TO_QUALITYPOOL} from '../../shared-objects';
import {ActivatedRoute} from '@angular/router';
import {BrokerMandateOverlayComponent} from '../broker-mandate-overlay/broker-mandate-overlay.component';
import {DetailComponent} from '../external-contracts-detail/detail.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {PreCheckOverlayComponent,} from '../pre-check-contract-transmission-overlay/pre-check-overlay.component';
import {PersonsService} from '../../services/persons.service';
import {catchError, switchMap, tap} from 'rxjs/operators';
import {BrokerContractOverlayComponent} from '../broker-contract-overlay/broker-contract-overlay.component';
import {ProvisionChangeOverlayComponent} from '../provision-change-overlay/provision-change-overlay.component';
import {QueueWarningOverlayComponent} from '../queue-warning-overlay/queue-warning-overlay.component';
import {
    ConfirmationService,
    KeycloakTokenService,
    NavigationButtonGroupComponent,
    ToastService
} from '@taures/angular-commons';
import {EMPTY, forkJoin, Observable, of} from 'rxjs'
import {KonzeptSubheaderComponent} from '../konzept-subheader/konzept-subheader.component';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {MatButton} from '@angular/material/button';
import {ProcessErrorService} from "../../services/process-error.service";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
    selector: 'tr-insurer-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    encapsulation: ViewEncapsulation.None,
    host: { class: 'flex flex-column overflow-y-auto h-full' },
    imports: [KonzeptSubheaderComponent, DetailComponent, MatProgressSpinner, MatButton, NavigationButtonGroupComponent]
})
export class ListComponent implements OnInit, AfterViewChecked {

    constructor(private route: ActivatedRoute,
                public externalContractsService: ExternalContractsService,
                private personsService: PersonsService,
                private dialog: MatDialog,
                private toastService: ToastService,
                private keycloakTokenService: KeycloakTokenService,
                private changeDetection: ChangeDetectorRef,
                private confirmationService: ConfirmationService,
                private processErrorService: ProcessErrorService) {
        this.route.params.subscribe(params => {
            this.personManagementId = params['personManagementId'];
            this.crmContractId = Number(params['crmContractId']);
            if (isNaN(this.crmContractId)) {
                this.crmContractId = -1;
            }
        });
    }

    personManagementId: string;
    crmContractId: number;
    showSpinner = false;

    @ViewChildren(DetailComponent) contractTiles !: QueryList<DetailComponent>;

    public savingActivated = false;
    public changesOnTiles = false;
    public eachTileHasChosenTariff = false;
    public processingActivated = false;
    public eachTileHasChosenInstruction = false;
    public customer: Customer;
    public hasBrokerMandate = false;

    ngOnInit() {
        this.externalContractsService.loadInsurerList().subscribe();
        this.externalContractsService.loadDivisionsList().subscribe();
        this.externalContractsService.loadContractData(this.personManagementId, this.crmContractId).subscribe();
        this.externalContractsService.loadBrokerContractExistence(this.personManagementId).subscribe();
        this.externalContractsService.loadUserIsAuthorized34d().subscribe();
        this.externalContractsService.loadUserIsAuthorizedToProcessContracts().subscribe();

        forkJoin([this.personsService.loadCustomer(this.personManagementId),
            this.externalContractsService.loadBrokerMandate(this.personManagementId)])
            .subscribe(([customer]) => {
                this.customer = customer;
                this.personManagementId = customer?.personManagementId;
                this.hasBrokerMandate = this.externalContractsService.hasBrokerMandate();
                if (!this.hasBrokerMandate) {
                    this.dialog.open(BrokerMandateOverlayComponent,
                        {
                            width: '600px',
                            data: {customerId: this.customer.id},
                            autoFocus: false
                        }
                    );
                }
            })
    }

    /**
     * checks every few seconds for changes on view and view children
     */
    ngAfterViewChecked() {
        this.checkEachTileHasChosenTariff();
        this.checkEachTileHasChosenInstruction();
        this.checkSavingActivated();
        this.checkProcessingActivated();
        this.changeDetection.detectChanges();
    }

    cancel(): void {
        if (this.changesOnTiles) {
             this.confirmationService.showPopup({
                message: 'Möchtest du wirklich fortfahren?\nAlle nicht gespeicherten Änderungen gehen verloren.',
                confirmationType: "warning",
                closable: false
            }).afterClosed().subscribe(result => {
                if (result) {
                    this.closeOrRedirectToKonzept();
                }
            });
        } else {
            this.closeOrRedirectToKonzept();
        }
    }

    private resetAfterSave(): void {
        this.resetChangesOnTiles();
        this.toastService.queueToastMessage({
            title: 'Bestätigung',
            message: 'Änderungen gespeichert',
            notificationType: 'success'
        })
    }

    doSave(): void {
        this.showSpinner = true;
        this.save().subscribe(() => {
            this.resetAfterSave()
            this.showSpinner = false
        })
    }

    save(): Observable<string> {
        const contracts: ExternalContractData[] = this.contractTiles
          .filter(tile => tile.formGroup.dirty)
          .map(tile => tile.getUpdatedExternalContract());

        if (contracts.length === 0) {
            return of(null)
        }

        return this.externalContractsService.saveContracts(contracts).pipe(
            catchError((err: HttpErrorResponse) => {
                this.showErrorMessage(err.message)
                return ExternalContractsService.handleError(err, 'Die Daten konnten nicht gespeichert werden!')
            })
        )
    }

    private checkSavingActivated(): void {
        this.changesOnTiles = false;
        this.contractTiles.forEach(tile => {
            if (tile.hasChanges) {
                this.changesOnTiles = true;
            }
        });

        this.savingActivated = this.changesOnTiles && this.eachTileHasChosenTariff;
    }

    private checkProcessingActivated(): void {
        this.processingActivated = this.externalContractsService.isUser34dRegistered()
            && this.externalContractsService.isUserAuthorizedToProcessContracts()
            && this.eachTileHasChosenInstruction
            && this.eachTileHasChosenTariff;
    }

    private showErrorMessage(err): void {
        this.toastService.queueToastMessage({
            message: err,
            notificationType: 'error'
        })
    }
    
    private openPreCheckOverlay(): MatDialogRef<PreCheckOverlayComponent, boolean | undefined> {
        return this.dialog.open(PreCheckOverlayComponent,
            {
                width: '800px',
                data: {
                    personManagementId: this.personManagementId,
                    contractTiles: this.contractTiles,
                    customerMailAddress: this.customer.email,
                    consultantMailAddress: this.keycloakTokenService.email,
                    hasBrokerMandate: this.hasBrokerMandate
                },
                autoFocus: false,
                disableClose: true
            }
        );
    }

    private handleProvisionChangeWithChecks(): Observable<boolean> {
        if (this.hasBrokerMandate) {
            return this.openProvisionChangeOverlay().afterClosed()
        }
        return this.openQueueWarningOverlay().afterClosed().pipe(
            switchMap(() => this.openProvisionChangeOverlay().afterClosed())
        )
    }

    process(): void {
        this.showSpinner = true;
        this.save().pipe(
            tap(() => this.resetAfterSave()),
            switchMap(() => this.externalContractsService.verifyContracts(this.personManagementId)),
            switchMap(() => this.openPreCheckOverlay().afterClosed()),
            switchMap((proceed: boolean) => {
                if (!proceed) {
                    this.showSpinner = false
                    return EMPTY
                }
                const anyContractShouldBeTransferred = this.contractTiles.some(tile =>
                    tile.getSelectedInstruction() !== undefined && tile.getSelectedInstruction().id === INSTRUCTION_TRANSFER_TO_QUALITYPOOL);
                if (anyContractShouldBeTransferred) {
                    return this.handleProvisionChangeWithChecks()
                }
                return of(false)
            }),
            switchMap((didChangeProvisions: boolean | null) => {
                if (didChangeProvisions === null) {
                    this.showSpinner = false
                    return EMPTY
                }
                const contractsWithoutBrokerContract = this.getContractTilesWithoutBrokerContract()
                if (!didChangeProvisions || contractsWithoutBrokerContract.length === 0) {
                    return of(null)
                }
                return this.openBrokerContractOverlay(contractsWithoutBrokerContract).afterClosed()
            }),
            catchError(err => {
                this.showSpinner = false;
                return this.processErrorService.handleProcessError(err)
            })
        ).subscribe(() => {
            this.showSpinner = false
            this.closeOrRedirectToKonzept();
        })
    }

    private openQueueWarningOverlay(): MatDialogRef<QueueWarningOverlayComponent> {
        return this.dialog.open(QueueWarningOverlayComponent,
            {
                width: '660px',
                autoFocus: false,
                disableClose: true
            }
        );
    }

    private resetChangesOnTiles(): void {
        this.contractTiles.forEach(tile => tile.hasChanges = false);
        this.changesOnTiles = false;
    }

    private checkEachTileHasChosenInstruction(): void {
        let allSet = this.contractTiles.length > 0;
        this.contractTiles.forEach(tile => {
            const currentFormControl = tile.formGroup.get('instructionId');
            if (tile.externalContractData.validationResult.instructions.length > 0 && currentFormControl.value <= 0) {
                allSet = false;
            }
        });
        this.eachTileHasChosenInstruction = allSet;
    }

    private checkEachTileHasChosenTariff(): void {
        let allSet = this.contractTiles.length > 0;
        this.contractTiles.forEach(tile => {
            if (tile.formGroup.invalid) allSet = false;
        });
        this.eachTileHasChosenTariff = allSet;
    }

    private openProvisionChangeOverlay(): MatDialogRef<ProvisionChangeOverlayComponent> {
        const originalProvisionRecipientIds = [];
        this.contractTiles.forEach(tile => {
            if (tile.getSelectedInstruction() !== undefined && tile.getSelectedInstruction().id === INSTRUCTION_TRANSFER_TO_QUALITYPOOL) {
                const provisionRecipient = tile.externalContractData.originalProvisionRecipientId;
                if (!originalProvisionRecipientIds.includes(provisionRecipient)) {
                    originalProvisionRecipientIds.push(provisionRecipient);
                }
            }
        });

        return this.dialog.open(ProvisionChangeOverlayComponent,
            {
                width: '660px',
                data: {
                    personManagementId: this.personManagementId,
                    originalProvisionRecipients: originalProvisionRecipientIds
                },
                disableClose: true,
                autoFocus: false
            }
        );
    }

    private openBrokerContractOverlay(contractTilesWithoutBrokerContract: DetailComponent[]): MatDialogRef<BrokerContractOverlayComponent> {
        return this.dialog.open(BrokerContractOverlayComponent,
            {
                width: '660px',
                data: {
                    personManagementId: this.personManagementId,
                    customerId: this.customer.id,
                    contractTiles: contractTilesWithoutBrokerContract
                },
                autoFocus: false,
                disableClose: true
            }
        );
    }

    closeOrRedirectToKonzept(): void {
        if (window.opener) {
            window.close();
        } else {
            window.location.href = `${this.externalContractsService.getKonzeptUrl(this.personManagementId)}`;
        }
    }

    private getContractTilesWithoutBrokerContract() {
        return this.contractTiles.filter(tile => {
            const brokerContractExistence = this.externalContractsService.getBrokerContractExistences()
                .find(existence => existence.divisionId === tile.externalContractData.division.id);
            // show only the ones that have no broker contract and should be transferred
            return (brokerContractExistence === undefined || brokerContractExistence.exists === false) &&
                tile.getSelectedInstruction() !== undefined && tile.getSelectedInstruction().id === INSTRUCTION_TRANSFER_TO_QUALITYPOOL;
        });
    }
}
