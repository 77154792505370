<div class="flex align-items-center py-4 px-3">
    <h2 mat-dialog-title class="p-0 mb-0">Hinweis</h2>
</div>
<mat-dialog-content>
    <span>{{ PROCESS_ERRORS.invalidBundle }}</span>
    <ul class="invalid-bundle-list">
        @for (contract of invalidBundles; track contract.contractNumber) {
            <li class="pb-1">{{ contract.insurer }} - {{ contract.contractNumber }}</li>
        }
    </ul>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close>Ok
    </button>
</mat-dialog-actions>
