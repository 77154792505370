import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogClose, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Division, Insurer, TransferType } from '../../shared-objects'
import { ExternalContractsService } from '../../services/external-contracts.service'
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { AsyncPipe } from '@angular/common';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { AutocompleteComponent } from '../autocomplete/autocomplete.component';

export interface DialogData {
  insurer: Insurer
}

@Component({
    selector: 'tr-insurer-change-overlay',
    templateUrl: './insurer-change-overlay.component.html',
    styleUrls: ['./insurer-change-overlay.component.scss'],
    imports: [MatDialogTitle, MatIconButton, MatDialogClose, MatIcon, MatDialogContent, FormsModule, ReactiveFormsModule, MatCheckbox, MatRadioGroup, MatRadioButton, MatFormField, MatLabel, MatInput, AutocompleteComponent, MatDialogActions, MatButton, AsyncPipe]
})
export class InsurerChangeOverlayComponent implements OnInit, OnDestroy {
  _onDestroy = new Subject<void>();
  form: UntypedFormGroup;
  loading = false;
  transferTypes = [
    { title: 'keine BÜ möglich', value: TransferType.NONE },
    { title: 'direkt', value: TransferType.DIRECT },
    { title: 'Qualitypool', value: TransferType.QUALITYPOOL }
  ]
  divisions: Observable<Division[]>

  constructor(@Inject(MAT_DIALOG_DATA) readonly data: DialogData,
              public externalContractsService: ExternalContractsService,
              private dialogRef: MatDialogRef<InsurerChangeOverlayComponent>,
              private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.createFormControls();
    this.divisions = this.externalContractsService.loadDivisionsList()
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  createFormControls(): void {
    this.form = this.formBuilder.group({
      transferType: [null, [Validators.required]],
      correspondenceOnly: null,
      hidden: null,
      divisionIds: null,
      email: [null, [Validators.email, Validators.required]],
      emailCC: [null, [Validators.email]],
      brokerNumber: ['', [Validators.maxLength(64), Validators.required]],
    });


    this.form.get('transferType').valueChanges.subscribe((v) => {
      ['email', 'emailCC', 'brokerNumber'].forEach((k: string) => v === TransferType.DIRECT ? this.form.get(k).enable() : this.form.get(k).disable())
    })

    this.form.reset(this.data.insurer);
  }

  onSubmit(): void {
    this.loading = true
    this.externalContractsService.saveInsurer({ ...this.data.insurer, ...this.form.value }).subscribe(() => this.dialogRef.close(), (e) => this.loading = false)
  }
}
