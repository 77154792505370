:host {
  display: flex;
}

.list-width {
  max-width: 1536px;
}

.control-pane {
  position: fixed !important;
  top: auto !important;
  bottom: 56px!important;
  right: 15px !important;
  left: auto !important;
  z-index: 999;

  display: table;
  background-color: white;

  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2),
  0 2px 2px 0 rgba(0, 0, 0, .14),
  0 1px 5px 0 rgba(0, 0, 0, .12);

  .centered {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    padding: 15px;
    width: calc(100% - 10px);
    width: -moz-calc(100% - 10px);
    width: -webkit-calc(100% - 10px);
  }

  .margin-right {
    margin-right: 10px;
  }

}
