import {
    AfterContentInit,
    ChangeDetectionStrategy,
    Component, Injectable,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {Division, ExternalContractData, Instruction, Insurer, Tariff} from '../../shared-objects';
import {ExternalContractsService} from '../../services/external-contracts.service';
import { FormGroupDirective, NgForm, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {map, takeUntil} from 'rxjs/operators';
import {ReplaySubject, Subject} from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { ErrorStateMatcher, MatOption } from "@angular/material/core";
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgClass, AsyncPipe } from '@angular/common';
import { MatChipSet, MatChipOption } from '@angular/material/chips';
import { MatInput } from '@angular/material/input';
import { MessageComponent } from '@taures/angular-commons';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { MatButton } from '@angular/material/button';

/** Error when invalid control is dirty, touched, or submitted. */
@Injectable()
export class ShowOnInvalidErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        return control && control.invalid;
    }
}

@Component({
    selector: 'tr-external-contract-detail',
    templateUrl: './detail.component.html',
    styleUrls: ['./detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'flex flex-column'
    },
    providers: [
        { provide: ErrorStateMatcher, useClass: ShowOnInvalidErrorStateMatcher },
    ],
    imports: [FormsModule, ReactiveFormsModule, MatFormField, MatLabel, MatSelect, MatOption, NgxMatSelectSearchModule, MatChipSet, MatChipOption, MatInput, MessageComponent, NgClass, MatRadioGroup, MatRadioButton, MatButton, AsyncPipe]
})
export class DetailComponent implements OnInit, AfterContentInit, OnDestroy {
    @Input() externalContractData: ExternalContractData;
    @Input() insurers: Insurer[];
    @Input() divisions: Division[];
    @Input() personManagementId: string;

    instructions = [
        { id: 1, label: 'Keine Bestandsübertragung gewünscht' },
        { id: 3, label: 'Bestandsübertragung, ggf. Korrespondenzmakler' },
    ]

    @ViewChild('singleSelectInsurer', {static: true}) singleSelectInsurer: MatSelect;
    @ViewChild('singleSelectDivision', {static: true}) singleSelectContractType: MatSelect;

    public insurersFilterCtrl: UntypedFormControl = new UntypedFormControl();
    public divisionsFilterCtrl: UntypedFormControl = new UntypedFormControl();
    filteredInsurers: ReplaySubject<Insurer[]> = new ReplaySubject<Insurer[]>(1);
    filteredDivisions: ReplaySubject<Division[]> = new ReplaySubject<Division[]>(1);

    formGroup: UntypedFormGroup;
    brokerContractExists: boolean;
    tariffs: Tariff[];
    selectedTariffIsUnknown: boolean;
    hasChanges: boolean;

    protected _onDestroy = new Subject<void>();

    constructor(private contractService: ExternalContractsService, private formBuilder: UntypedFormBuilder) {
    }

    ngOnInit() {
        this.createFormControls();
        this.loadTariffsForContract();
        this.filteredInsurers.next(this.insurers.slice());
        this.filteredDivisions.next(this.divisions.slice());

        // listen for insurers search field value changes
        this.insurersFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterInsurers();
            });

        // listen for insurers search field value changes
        this.divisionsFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterDivisions();
            });
    }

    ngAfterContentInit() {
        this.setInitialInsurerValue();
        this.setInitialDivisionsValue().then(() => {
            this.brokerContractExists = this.contractService.hasBrokerContract(this.externalContractData.division.id);
        });
        this.registerCheckHasChanges();
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    createFormControls(): void {
        this.formGroup = this.formBuilder.group({
            'divisionId': [this.externalContractData.division.id, Validators.required],
            'insurerId': [this.externalContractData.insurer.id, Validators.required],
            'tariffId': [this.externalContractData.tariff.id, Validators.required],
            'unknownTariffName': [this.externalContractData.unknownTariffName],
            'contractNumber': [this.externalContractData.contractNumber, Validators.minLength(1)],
            'instructionId': [this.externalContractData.chosenInstruction?.id ?? '', Validators.required]
        });
        if (!this.contractService.isUserAuthorizedToProcessContracts()) {
            this.formGroup.get('instructionId').disable();
        }
    }

    registerCheckHasChanges(): void {
        this.hasChanges = false;
        this.formGroup.valueChanges.subscribe(() => {
            this.hasChanges = true;
        });
        this.formGroup.get('insurerId').valueChanges.subscribe((next) => this.resetTariff());
        this.formGroup.get('divisionId').valueChanges.subscribe((next) => this.resetTariff());
        this.formGroup.get('instructionId').valueChanges.subscribe((next) => {
            if (this.externalContractData.chosenInstruction != null) {
                this.externalContractData.chosenInstruction.id = next;
                delete this.externalContractData.chosenInstruction.text;
                this.refreshInstructions()
            }
        });
    }

    getUpdatedExternalContract(): ExternalContractData {
        this.externalContractData.division = this.getSelectedDivision();
        this.externalContractData.insurer = this.getSelectedInsurer();
        this.externalContractData.chosenInstruction = this.getSelectedInstruction();
        this.externalContractData.tariff = this.getSelectedTariff();
        this.externalContractData.unknownTariffName = this.formGroup.get('unknownTariffName').value;
        this.externalContractData.contractNumber = this.formGroup.get('contractNumber').value;
        return this.externalContractData;
    }

    divisionChangeAction(divisionId: number): void {
        this.brokerContractExists = this.contractService.hasBrokerContract(divisionId);
        this.externalContractData.division = this.divisions.find(x => x.id === divisionId);
        this.loadTariffs();
        this.refreshInstructions();
    }

    insurerChangeAction(insurerId: any): void {
        this.externalContractData.insurer = this.insurers.find(x => x.id === insurerId);
        this.loadTariffs();
        this.refreshInstructions();
    }

    tariffChangeAction(tariffId: any): void {
        this.externalContractData.tariff = this.tariffs.find(x => x.id === tariffId);
        this.selectedTariffIsUnknown = this.externalContractData.tariff.unknown;
    }

    contractNumberChangeAction(contractNumber: string): void {
        const trimmedContractNumber = contractNumber.trim();
        this.externalContractData.contractNumber = trimmedContractNumber;
        this.formGroup.patchValue({'contractNumber': trimmedContractNumber});
        this.refreshInstructions();
    }

    resetTileAction(): void {
        this.contractService.fetchContractData(this.externalContractData.customerId, this.externalContractData.crmContractId)
            .pipe(
                map(list => {
                    this.externalContractData = list[0];
                    this.ngOnInit();
                    this.ngAfterContentInit();
                })
            ).subscribe();
    }

    refreshInstructions(): void {
        this.contractService
            .fetchValidationResult(this.externalContractData)
            .pipe(
                map(
                    validationResult => {
                        this.externalContractData.validationResult = validationResult;
                        const currentChoosenInstruction = this.formGroup.get('instructionId').value;
                        let instructionChoosable = validationResult.instructions
                          .find(instruction => instruction.id === currentChoosenInstruction) != null;
                        if (currentChoosenInstruction!== null && !instructionChoosable) {
                            this.formGroup.patchValue({'instructionId': null});
                        }
                    }))
            .subscribe();
    }

    protected setInitialInsurerValue(): void {
        this.singleSelectInsurer.writeValue(this.externalContractData.insurer.id);
    }

    protected setInitialDivisionsValue(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.singleSelectContractType.writeValue(this.externalContractData.division.id);
            resolve();
        });
    }

    private loadTariffsForContract(): void {
        this.contractService.fetchTariffsForContract(this.externalContractData.id)
            .subscribe(tariffs =>
                this.updateTariffs(tariffs)
            );
    }

    private loadTariffs(): void {
        this.contractService.fetchTariffs(this.externalContractData.insurer.id, this.externalContractData.division.id)
            .subscribe(tariffs =>
                this.updateTariffs(tariffs)
            );
    }

    private updateTariffs(tariffs: Tariff[]) {
        this.tariffs = tariffs;
        this.selectedTariffIsUnknown = this.getSelectedTariff() === undefined ? false : this.getSelectedTariff().unknown;
        if (this.getSelectedTariff() === undefined) {
            this.resetTariff()
        }
    }

    private resetTariff () {
        this.formGroup.get('tariffId').markAsTouched()
        if (this.formGroup.get('tariffId').value) {
            this.formGroup.get('tariffId').setValue(null);
            this.formGroup.get('unknownTariffName').setValue(null);
        }
    }

    protected filterInsurers(): void {
        if (!this.insurers) {
            return;
        }
        // get the search keyword
        let search = this.insurersFilterCtrl.value;
        if (!search) {
            this.filteredInsurers.next(this.insurers.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the insurers
        this.filteredInsurers.next(
            this.insurers.filter(insurer => insurer.name.toLowerCase().indexOf(search) > -1)
        );
    }

    protected filterDivisions() {
        if (!this.divisions) {
            return;
        }
        // get the search keyword
        let search = this.divisionsFilterCtrl.value;
        if (!search) {
            this.filteredDivisions.next(this.divisions.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the insurers
        this.filteredDivisions.next(
            this.divisions.filter(contractType => contractType.label.toLowerCase().indexOf(search) > -1)
        );
    }

    getSelectedDivision(): Division {
        return this.divisions.find(x => x.id === this.formGroup.get('divisionId').value);
    }

    getSelectedInsurer(): Insurer {
        return this.insurers.find(x => x.id === this.formGroup.get('insurerId').value);
    }

    getSelectedTariff(): Tariff {
        return this.tariffs.find(x => x.id === this.formGroup.get('tariffId').value);
    }

    getSelectedInstruction(): Instruction {
        return this.externalContractData.validationResult.instructions
            .find(x => x.id === this.formGroup.get('instructionId').value);
    }

    getDevisionLabel () {
      return this.divisions?.find((d) => d.id === this.formGroup.get('divisionId').value).label
    }

    getInsurerName () {
      return this.insurers?.find((d) => d.id === this.formGroup.get('insurerId').value).name
    }

    isInstructionDisabled (id: number) {
        return !this.externalContractData.validationResult.instructions.find(s => s.id === id)
    }
}
