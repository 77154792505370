import { Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { map, startWith } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete'
import { AsyncPipe } from '@angular/common';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatChipGrid, MatChipRow, MatChipRemove, MatChipInput } from '@angular/material/chips';
import { MatIcon } from '@angular/material/icon';
import { MatOption } from '@angular/material/core';

interface AutocompleteItem {
  id: unknown
  label: string
}

@Component({
    selector: 'tr-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AutocompleteComponent),
            multi: true
        }
    ],
    imports: [MatFormField, MatLabel, MatChipGrid, MatChipRow, MatChipRemove, MatIcon, FormsModule, MatAutocompleteTrigger, MatChipInput, ReactiveFormsModule, MatAutocomplete, MatOption, AsyncPipe]
})
export class AutocompleteComponent<T extends AutocompleteItem> implements ControlValueAccessor {
  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;
  @Input() options: T[]
  onChange: any = () => {}
  onTouch: any = () => {}
  disabled: boolean
  _value: unknown[]
  searchControl: UntypedFormControl = new UntypedFormControl("")
  filteredOptions: Observable<T[]>
  @Input() label: string
  @Input() placeholder: string

  set value (value: unknown[]) {  // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
    this._value = value
    this.onChange(value)
    this.onTouch(value)
  }

  get value () {
    return this._value
  }

  writeValue(value: unknown[]): void {
    this._value = value
  }

  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  constructor() {
    this.filteredOptions = this.searchControl.valueChanges.pipe(
      startWith(null),
      map((item: string | null) => this._filter(typeof item === 'string' ? item : undefined)),
    );
  }

  remove(itemId: unknown): void {
    const index = this.value.indexOf(itemId);
    if (index >= 0) this.value.splice(index, 1);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.value.push((event.option.value as T).id);
    this.searchInput.nativeElement.value = '';
    this.searchControl.setValue('');
  }

  select ($event: MouseEvent, item: T): void {
    if (!$event.ctrlKey && !$event.metaKey) return;
    $event.preventDefault();
    this.value.push(item.id);
    this.searchControl.setValue(this.searchControl.value);
    this.searchInput.nativeElement.focus();
  }

  private _filter(value?: string): T[] {
    const prefiltered = this.options?.filter(item => !this.value.includes(item.id)) ?? []
    if (!value) return prefiltered
    const filterValue = value.toLowerCase();
    return prefiltered.filter(item => item.label.toLowerCase().includes(filterValue));
  }

  toLabel (id: unknown) {
    return this.options?.find(o => o.id === id)?.label ?? id
  }
}
