import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivationEnd, Router, RouterOutlet } from '@angular/router'
import { filter } from 'rxjs/operators'
import { ToastComponent } from '@taures/angular-commons';
import { KonzeptHeaderComponent } from './components/konzept-header/konzept-header.component';
import { KonzeptFooterComponent } from './components/konzept-footer/konzept-footer.component';

@Component({
    selector: 'tr-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    imports: [ToastComponent, KonzeptHeaderComponent, RouterOutlet, KonzeptFooterComponent]
})
export class AppComponent implements OnInit {
  defaultTitle = "Echse (Fremdverträge)"
  title = this.defaultTitle;

  constructor(private router: Router, private changeDetector: ChangeDetectorRef) { }

  ngOnInit() {
    this.router.events
      .pipe(filter((e) => e instanceof ActivationEnd))
      .subscribe((e: ActivationEnd) => {
        this.title = e.snapshot.data.title ?? this.defaultTitle
        this.changeDetector.markForCheck()
      })
  }
}
