.contracts {
  margin-top: 16px;
  margin-bottom: 16px;
}


h3 {
  font-size: 1.1em;
  margin-bottom: 0;
}


