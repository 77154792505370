import {ChangeDetectionStrategy, Component, Inject, QueryList, ViewEncapsulation} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import {DetailComponent} from '../external-contracts-detail/detail.component';
import {ExternalContractsService} from '../../services/external-contracts.service';
import { MatButton } from '@angular/material/button';

export interface BrokerContractOverlayData {
  personManagementId: string;
  customerId: number;
  contractTiles: QueryList<DetailComponent>;
}

@Component({
    selector: 'tr-broker-contract-overlay',
    templateUrl: './broker-contract-overlay.component.html',
    styleUrls: ['./broker-contract-overlay.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    imports: [MatDialogTitle, MatDialogContent, MatDialogActions, MatButton]
})
export class BrokerContractOverlayComponent {

  constructor(@Inject(DOCUMENT) private document: Document,
              @Inject(MAT_DIALOG_DATA) readonly data: BrokerContractOverlayData,
              private externalContractsService: ExternalContractsService,
              private dialogRef: MatDialogRef<BrokerContractOverlayComponent>) {
  }

  close(): void {
    this.dialogRef.close();
  }

  redirectToKonzept(): void {
    if (window.opener) {
        window.close()
    } else {
        this.document.location.href = `${this.externalContractsService.getKonzeptUrl(this.data.personManagementId)}`;
    }
  }

  redirectToBrokerContractGenerator(): void {
    this.document.location.href = `${this.externalContractsService.getDocsUrl(this.data.customerId)}/broker-contract`;
  }
}
