import {enableProdMode, importProvidersFrom, inject, provideAppInitializer} from '@angular/core';
import {environment} from './environments/environment';
import {AppInitService} from './external-contracts/services/app-init.service';
import {LogoutService} from '@taures/angular-commons';
import {OauthLogoutService} from './external-contracts/services/oauth-logout.service';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {GermanPaginatorIntl} from './external-contracts/services/german-paginator-intl';
import {provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {APP_ROUTES} from './external-contracts/app-routes';
import {provideAnimations} from '@angular/platform-browser/animations';
import {bootstrapApplication} from '@angular/platform-browser';
import {OAuthModule} from 'angular-oauth2-oidc';
import {AppComponent} from './external-contracts/app.component';
import {provideRouter} from "@angular/router";

if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(
            OAuthModule.forRoot({resourceServer: {sendAccessToken: true}})
        ),
        {
            provide: LogoutService,
            useClass: OauthLogoutService
        },
        provideAppInitializer(async () => {
            await inject(AppInitService).init();
        }),
        {
            provide: MatPaginatorIntl,
            useClass: GermanPaginatorIntl
        },
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations(),
        provideRouter(APP_ROUTES)
    ]
})
    .catch(err => console.error(err));
