import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {Consultant, Customer} from '../shared-objects';

@Injectable({providedIn: 'root'})
export class PersonsService {

    private consultantsInLowerHierarchy: Consultant[];

    constructor(private http: HttpClient, @Inject(LOCALE_ID) private locale: string) {
    }

    getConsultantsInLowerHierarchy(): Consultant[] {
        return this.consultantsInLowerHierarchy;
    }

    private prepareHttpOptions() {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json;charset=utf-8',
                Accept: 'application/json'
            })
        };
    }

    loadCustomer(personManagementId: string): Observable<Customer> {
        const url = `/api/customers/${personManagementId}`;
        return this.http.get<Customer>(url, this.prepareHttpOptions());
    }

    loadConsultantsInLowerHierarchy(name: string): Observable<void> {
        const url = `/api/consultants/hierarchy?name=${name}`;
        return this.http.get<Consultant[]>(url, this.prepareHttpOptions())
            .pipe(
                map(consultants => {
                    this.consultantsInLowerHierarchy = consultants;
                })
            );
    }

}
