import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PersonsService} from '../../services/persons.service';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import {MatSelect} from '@angular/material/select';
import {Subject, takeUntil} from 'rxjs';
import {Consultant} from '../../shared-objects';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {ExternalContractsService} from '../../services/external-contracts.service';
import {MatFormField} from '@angular/material/form-field';
import {MatOption} from '@angular/material/core';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {DatePipe} from '@angular/common';
import {MatButton} from '@angular/material/button';
import {catchError} from "rxjs/operators";
import {ProcessErrorService} from "../../services/process-error.service";

export interface DialogData {
  personManagementId: string;
  originalProvisionRecipients: string[];
}

@Component({
    selector: 'tr-provision-change-overlay',
    templateUrl: './provision-change-overlay.component.html',
    styleUrls: ['./provision-change-overlay.component.scss'],
    imports: [MatDialogTitle, MatDialogContent, FormsModule, ReactiveFormsModule, MatFormField, MatSelect, MatOption, NgxMatSelectSearchModule, MatDialogActions, MatButton, DatePipe]
})
export class ProvisionChangeOverlayComponent implements OnInit, OnDestroy {

  @ViewChild('singleSelectConsultant', {static: true}) singleSelectConsultant: MatSelect;

  _onDestroy = new Subject<void>();
  formGroup: UntypedFormGroup;
  consultantsSearchCtrl: UntypedFormControl = new UntypedFormControl();
  provisionRecipientNames = '';

  constructor(@Inject(MAT_DIALOG_DATA) readonly data: DialogData,
              public personsService: PersonsService,
              private processErrorService: ProcessErrorService,
              private dialogRef: MatDialogRef<ProvisionChangeOverlayComponent>,
              private externalContractsService: ExternalContractsService,
              private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.createFormControls();
    this.personsService.loadConsultantsInLowerHierarchy('').subscribe();
    // fetch names of original provision recipients by fetching their customer-data
    this.data.originalProvisionRecipients.forEach(provisionRecipientId => {
      if (provisionRecipientId && provisionRecipientId.length > 0) {
        this.personsService.loadCustomer(provisionRecipientId).subscribe(customer => {
          if (this.provisionRecipientNames.length > 0) {
            this.provisionRecipientNames += ', ';
          }
          this.provisionRecipientNames += `${customer.firstName} ${customer.lastName}`;
        });
      }
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  createFormControls(): void {
    this.formGroup = this.formBuilder.group({
      // always select first entry
      'consultantId': [0, Validators.required]
    });
  }

  getSelectedConsultant(): Consultant {
    if (this.formGroup.get('consultantId').value !== 0) {
      return this.personsService.getConsultantsInLowerHierarchy().find(x => x.id === this.formGroup.get('consultantId').value);
    }
    return null;
  }

  submit(): void {
    const consultant = this.getSelectedConsultant();
    if (!consultant) {
      this.dialogRef.close(true)
    } else {
      this.externalContractsService.changeProvisionRecipient(this.data.personManagementId, consultant.id).pipe(
        takeUntil(this._onDestroy),
        catchError((err) => {
          this.dialogRef.close(null);
          return this.processErrorService.handleProcessError(err)
        })
      ).subscribe(() => {
        this.dialogRef.close(true);
      });
    }
  }

  fetchConsultantsByPartialName() {
    this.personsService.loadConsultantsInLowerHierarchy(this.consultantsSearchCtrl.value).subscribe(() =>
      console.error(this.personsService.getConsultantsInLowerHierarchy().length)
    );
  }
}
