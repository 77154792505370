<h2 mat-dialog-title class="mb-0">Zusammenfassung</h2>
<mat-dialog-content>
    <p class="font-normal">Folgende Verträge sollen in den Bestand bzw. als Korrespondenzmakler übernommen werden:</p>
    <div class="contracts">
        <ul class="list-disc">
            @for (contract of contractsToTransfer; track contract.number) {
                <li>{{ contract.contractTypeName }}, {{ contract.insurerName }}, {{ contract.number }}</li>
            } @empty {
                <li>Keine Verträge zur Übertragung ausgewählt</li>
            }
        </ul>
    </div>
    @if (contractsNotToTransfer.length) {
        <p class="font-normal">Folgende Verträge sollen <b>nicht</b> in den Bestand übernommen werden:</p>
        <div class="contracts">
            <ul class="list-disc">
                @for (contract of contractsNotToTransfer; track contract.number) {
                    <li>
                        {{ contract.contractTypeName }}, {{ contract.insurerName }}, {{ contract.number }}
                    </li>
                }
            </ul>
        </div>
    }
    <div class="mb-4">
        Die <b>Dokumentation</b> des Kundenwunsches wird automatisch erstellt und an folgende
        E-Mail-Adresse{{ data.consultantMailAddress && "n" }}
        versendet:<br/><br/>
        @if (data.customerMailAddress) {
            <span>{{ data.customerMailAddress }}<br/></span>
        }
        @if (data.consultantMailAddress) {
            <span>{{ data.consultantMailAddress }}</span>
        }
    </div>
    <mat-checkbox color="primary" class="top-aligned-checkbox"
                  [formControl]="confirmationForm.controls.contentValidated">
        Bitte bestätigen:
    </mat-checkbox>
    <p>Der Umfang der zu übertragenden Verträge wurde geprüft
        und mit dem Kundenbedarf abgeglichen. Im Falle einer Deckungslücke wurde der Kunde über die M&ouml;glichkeiten
        einer Aufstockung seines Vertrags oder den Abschluss einer Differenzdeckung informiert.</p>
    @if (confirmationForm.controls.documentationWithoutMail.enabled) {
        <mat-checkbox color="primary" class="top-aligned-checkbox mt-2"
                      [formControl]="confirmationForm.controls.documentationWithoutMail">Bitte bestätigen:
        </mat-checkbox>
        <p>
            Für den Kunden ist keine E-Mail-Adresse
            hinterlegt, oder er hat deren Nutzung nicht zugestimmt. Ich bestätige, dass ich dem Kunden die
            Dokumentation
            in anderer geeigneter Form (z. B. in Papierform oder auf Datenträger) aushändige.
        </p>
    }
    @if (contractsToTransfer.length && !data.hasBrokerMandate) {
        <tr-message class="block my-4" type="warning" title="Wichtig" message="Die Bestandsübertragung wird erst nach Einreichung und Prüfung
        der aktuellen Maklervollmacht (innerhalb von max. 30 Tagen) versendet!">
        </tr-message>
    }
    <mat-dialog-actions class="p-0" align="end">
        <button mat-stroked-button color="link" mat-dialog-close [disabled]="transferring">Bestandsübertragung abbrechen</button>
        <button mat-flat-button color="primary" (click)="startTransfer()"
                [disabled]="transferring || (!contractsToTransfer.length && !contractsNotToTransfer.length) || this.confirmationForm.invalid">
            @if (contractsToTransfer.length) {
                <span>{{ fetchSubmitButtonText() }}</span>
            } @else if (contractsNotToTransfer) {
                <span>Kundenwunsch dokumentieren</span>
            }
        </button>
    </mat-dialog-actions>
</mat-dialog-content>
