<div class="flex flex-grow-1 flex-column shadow-2" [formGroup]="formGroup">
    <div class="detail-card-header px-3 py-075 taures-blue-1 white-space-nowrap line-height-3 overflow-x-hidden">{{ getDevisionLabel() }}
        ({{ getInsurerName() }})
    </div>
    <div class="px-3 py-125">
        <div class="flex">
            <mat-form-field class="block flex-grow-1">
                <mat-label>Sparte</mat-label>
                <mat-select formControlName="divisionId" id="divisionId" required
                            (selectionChange)="divisionChangeAction($event.value)"
                            #singleSelectDivision>
                    <mat-option>
                        <ngx-mat-select-search [formControl]="divisionsFilterCtrl"></ngx-mat-select-search>
                    </mat-option>
                    @for (division of filteredDivisions | async; track division.id) {
                        <mat-option [value]="division.id">
                            {{ division.label }}
                        </mat-option>
                    }

                </mat-select>
            </mat-form-field>
            <div class="pl-2 flex align-items-center pb-2">
                <mat-chip-set>
                    @if (brokerContractExists) {
                        <mat-chip-option class="chip-height taures-blue-2 opacity-100 text-xs" disabled="true">Mit
                            Maklervertrag
                        </mat-chip-option>
                    } @else {
                        <mat-chip-option disabled="true"
                                         class="chip-height taures-warn opacity-100 text-xs">Ohne Maklervertrag
                        </mat-chip-option>
                    }

                </mat-chip-set>
            </div>
        </div>

        <mat-form-field class="block">
            <mat-label>Versicherer</mat-label>
            <mat-select formControlName="insurerId" id="insurerId" required
                        (selectionChange)="insurerChangeAction($event.value)"
                        #singleSelectInsurer>
                <mat-option>
                    <ngx-mat-select-search [formControl]="insurersFilterCtrl"></ngx-mat-select-search>
                </mat-option>
                @for (insurer of filteredInsurers | async; track insurer.id) {
                    <mat-option [value]="insurer.id">
                        {{ insurer.name }}
                    </mat-option>
                }

            </mat-select>
        </mat-form-field>

        <mat-form-field class="block">
            <mat-label>Tarifbezeichnung</mat-label>
            <mat-select formControlName="tariffId"
                        id="tariffId"
                        (selectionChange)="tariffChangeAction($event.value)"
                        required
            >
                @for (tariff of tariffs; track tariff.id) {
                    <mat-option [value]="tariff.id">
                        {{ tariff.name }}
                    </mat-option>
                }

            </mat-select>
        </mat-form-field>

        @if (selectedTariffIsUnknown) {
            <mat-form-field class="block">
                <mat-label>Neue Tarifbezeichnung</mat-label>
                <input matInput formControlName="unknownTariffName">
            </mat-form-field>
        }

        <mat-form-field class="block">
            <mat-label>Versicherungsnummer</mat-label>
            <input matInput (change)="contractNumberChangeAction(formGroup.get('contractNumber').value)"
                   formControlName="contractNumber"
                   maxlength="30"
                   required>
        </mat-form-field>
        @if (externalContractData.validationResult.reasons.length > 0) {
            <tr-message [type]="externalContractData.validationResult.error ? 'warning' : 'information'"
                        class="mb-3 block">
                <ng-container message>
                    @if (externalContractData.validationResult.reasons.length === 1) {
                        <div>{{ externalContractData.validationResult.reasons[0] }}</div>
                    } @else {
                        <ul class="list-disc ml-125 mt-0 mb-0 p-0">
                            @for (reason of externalContractData.validationResult.reasons; track reason) {
                                <li class="py-1">{{ reason }}</li>
                            }
                        </ul>
                    }
                </ng-container>
            </tr-message>
        }
        <div>
            <label id="instruction-id" class="block font-base option-label"
                   [ngClass]="{'invalid-label': formGroup.get('instructionId').invalid}">Optionen *</label>
            @if (externalContractData.validationResult.instructions) {
                <mat-radio-group class="blue-radio-label" formControlName="instructionId"
                                 aria-labelledby="instruction-id">
                    @for (instruction of instructions; track instruction.id) {
                        <mat-radio-button color="primary" [value]="instruction.id"
                                          [disabled]="isInstructionDisabled(instruction.id)"
                                          class="block radio-medium-label">
                            {{ instruction.label }}
                        </mat-radio-button>
                    }
                </mat-radio-group>
            }
        </div>
    </div>
    <div class="flex-grow-1"></div>
    @if (hasChanges) {
        <div class="flex px-3 pb-3">
            <div class="flex-grow-1"></div>
            <button mat-stroked-button color="primary" (click)="resetTileAction()">Änderungen verwerfen</button>
        </div>
    }
</div>
