@if (options) {
    <mat-form-field class="block">
        <mat-label>{{ label }}</mat-label>
        <mat-chip-grid #chipList aria-label="Selection">
            @for (itemId of value; track itemId) {
                <mat-chip-row (removed)="remove(itemId)">
                    {{ toLabel(itemId) }}
                    <button matChipRemove>
                        <mat-icon>cancel</mat-icon>
                    </button>
                </mat-chip-row>
            }
        </mat-chip-grid>
        <input #searchInput
               [placeholder]="placeholder"
               [formControl]="searchControl"
               [matAutocomplete]="auto"
               [matChipInputFor]="chipList">
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event);">
            @for (item of filteredOptions | async; track item.id) {
                <mat-option [value]="item">
                    <div (click)="select($event, item)">{{ item.label }}</div>
                </mat-option>
            }
        </mat-autocomplete>
    </mat-form-field>
}
