@use '@angular/material' as mat;
@use "@taures/angular-commons/taures.theme" as theme;

tr-external-contract-detail {
  :host {
    display: flex
  }

  .card {
    padding: 24px;
  }

  .buttons {
    margin-bottom: -16px;
    margin-right: -16px;
    padding: 12px 0 0;
  }

  .mat-form-field {
    width: auto !important;
  }

  .header {
    margin: -24px -24px 0px -24px !important;
    padding: 8px 8px 0px 8px !important;

    .source {
      text-align: end;
    }
  }

  .content {
    margin-top: 24px;
  }

  .white-dropdown {
    .mat-select-value,
    .mat-form-field-label,
    .mat-select-value-text,
    .mat-select-arrow {
      color: white;
    }

    .mat-form-field-underline {
      background-color: white;
    }

  }

  .white-dropdown.mat-focused.mat-primary {
    .mat-select-arrow {
      color: white;
    }
  }

  .white-dropdown.mat-focused {
    .mat-form-field-ripple {
      background-color: white;
    }

    .mat-form-field-label {
      color: white;
    }
  }

  .detail-card-header {
    font-size: 16px;
  }

  .chip-height {
    max-height: 24px;
  }

  .box-icon-scale {
    transform: scale(.75);
    margin-left: -0.25rem;
  }

  .option-label {
    font-size: 75%;
    color: var(--mdc-filled-text-field-label-text-color);
  }

  .invalid-label {
    color: mat.get-theme-color(theme.$taures-theme, warn);
  }
}
