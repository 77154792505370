<main class="flex-1 overflow-y-auto pt-4">
    <div class="max-w-screen-2xl mx-auto px-4 py-4">
        <div class="filter-header">
            <mat-form-field appearance="fill" class="block">
                <mat-label class="text-sm">Filter</mat-label>
                <input matInput [formControl]="filterControl">
            </mat-form-field>
        </div>

        <div class="table-container mat-elevation-z4">
            <mat-table mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>ID</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.id}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Gesellschaft</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.name}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="hidden">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>ausgeblendet</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <mat-checkbox color="primary" class="example-margin" [checked]="element.hidden" disabled></mat-checkbox>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="transferType">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Einreichung</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{mapTransferType(element.transferType)}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="correspondenceOnly">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>nur Korrespondenz</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.correspondenceOnly ? 'Korrespondenz' : '---'}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="email">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>E-Mail</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.transferType === 'DIRECT' ? element.email : '---'}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions" stickyEnd="true">
                    <mat-header-cell *matHeaderCellDef>Aktionen</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <a mat-icon-button matTooltip="Gesellschaft bearbeiten" (click)="editInsurer(element)">
                            <mat-icon>edit</mat-icon>
                        </a>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>

            <mat-paginator [pageSizeOptions]="[50, 250, 500]">
            </mat-paginator>
        </div>
    </div>
</main>
