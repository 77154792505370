import {Component, Inject} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle
} from "@angular/material/dialog";
import {MatIcon} from "@angular/material/icon";
import {PROCESS_ERRORS} from "../pre-check-contract-transmission-overlay/pre-check-overlay.component";
import {MatButton} from "@angular/material/button";

export type InvalidBundleDialogData = {insurer: string, contractNumber: string}[];


@Component({
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatButton,
    MatDialogClose
  ],
  templateUrl: './invalid-bundle-warning-overlay.component.html',
  styleUrl: './invalid-bundle-warning-overlay.component.scss'
})
export class InvalidBundleWarningOverlayComponent {
  constructor(@Inject(MAT_DIALOG_DATA) protected invalidBundles: InvalidBundleDialogData) {
  }

  protected readonly PROCESS_ERRORS = PROCESS_ERRORS;
}

