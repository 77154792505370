import {Component} from '@angular/core';
import {MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle} from '@angular/material/dialog';
import {MatButton} from '@angular/material/button';

@Component({
    selector: 'tr-queue-warning-overlay',
    templateUrl: './queue-warning-overlay.component.html',
    styleUrls: ['./queue-warning-overlay.component.scss'],
    imports: [MatDialogTitle, MatDialogContent, MatDialogActions, MatButton]
})
export class QueueWarningOverlayComponent {

  constructor(private dialogRef: MatDialogRef<QueueWarningOverlayComponent>) { }

  close(): void {
    this.dialogRef.close();
  }

}
