<tr-konzept-subheader class="z-1" [customer]="customer"></tr-konzept-subheader>
@let dataLoaded = externalContractsService.getDivisions()
    && externalContractsService.getInsurers()
    && externalContractsService.getContracts()
    && externalContractsService.getBrokerContractExistences();
<main class="flex-1 overflow-y-auto pt-3 pb-8">
    <div class="list-width mx-auto">
        @if (dataLoaded) {
            <div class="grid px-2 m-0">
                @for (externalContract of externalContractsService.getContracts(); track externalContract.id) {
                    <div class="col-12 lg:col-6 xl:col-4 pt-0 pb-3">
                        <tr-external-contract-detail class="h-full"
                                                     [divisions]="externalContractsService.getDivisions()"
                                                     [insurers]="externalContractsService.getInsurers()"
                                                     [externalContractData]="externalContract"
                                                     [personManagementId]="personManagementId">
                        </tr-external-contract-detail>
                    </div>
                }
            </div>
        } @else if (showSpinner) {
            <div class="absolute top-0 left-0 right-0 bottom-0 bg-white-alpha-50 flex justify-content-center align-items-center">
                <mat-progress-spinner mode="indeterminate" [diameter]="48" [strokeWidth]="4"></mat-progress-spinner>
            </div>
        }
    </div>
</main>
@if (dataLoaded) {
    <tr-navigation-button-group>
        <button mat-raised-button color="link" (click)="cancel()" [disabled]="showSpinner" class="margin-right">
            Schließen
        </button>
        <button mat-raised-button color="link" class="margin-right" [disabled]="!savingActivated" (click)="doSave()">
            Speichern
        </button>
        <button mat-raised-button color="primary" [disabled]="!processingActivated || showSpinner" (click)="process()">
            Weiter
        </button>
    </tr-navigation-button-group>
}
