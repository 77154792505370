import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    OnDestroy,
    OnInit,
    QueryList,
    ViewEncapsulation
} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {
    MAT_DIALOG_DATA,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogRef,
    MatDialogTitle
} from '@angular/material/dialog';
import {DetailComponent} from '../external-contracts-detail/detail.component';
import {ExternalContractsService} from '../../services/external-contracts.service';
import {ExternalContractData, INSTRUCTION_NO_TRANSFER, INSTRUCTION_TRANSFER_TO_QUALITYPOOL} from '../../shared-objects';
import {catchError, switchMap} from 'rxjs/operators';
import {MessageComponent, ToastService} from "@taures/angular-commons";
import {MatCheckbox} from '@angular/material/checkbox';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatButton} from '@angular/material/button';
import {Subject, takeUntil} from "rxjs";
import {ProcessErrorService} from "../../services/process-error.service";

export interface PreCheckDialogData {
    personManagementId: string;
    contractTiles: QueryList<DetailComponent>;
    customerMailAddress: string;
    consultantMailAddress: string;
    hasBrokerMandate: boolean;
}

export class ContractData {
    number: string;
    insurerName: string;
    contractTypeName: string;
}

export const PROCESS_ERRORS = {
    'documentation': 'Bei der Dokumentation ist ein Fehler aufgetreten. Bitte starte den Vermittlerwechsel zu einem späteren Zeitpunkt noch einmal.',
    'noAddress': 'Keine gültige Hauptanschrift erfasst. Eine Bestandsübertragung ist nicht möglich.',
    'invalidAddress': 'Fehlerhafte Meldeadresse. Eine Bestandsübertragung ist nicht möglich.',
    'invalidBundle': 'Uneinheitliche Entscheidung für Bündelvertragssparten bitte korrigieren:',
    'unknown': 'Die Aktionen können derzeit nicht durchgeführt werden, bitte versuchen Sie es später noch einmal.',
}

@Component({
    selector: 'tr-pre-check-overlay',
    templateUrl: './pre-check-overlay.component.html',
    styleUrls: ['./pre-check-overlay.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    imports: [MatDialogTitle, MatDialogContent, MessageComponent, MatCheckbox, FormsModule, MatDialogActions, MatButton, MatDialogClose, ReactiveFormsModule]
})
export class PreCheckOverlayComponent implements OnInit, OnDestroy {

    _onDestroy = new Subject<void>();
    contractsToTransfer: ContractData[] = [];
    contractsNotToTransfer: ContractData[] = [];
    contractsAll: ExternalContractData[] = [];
    confirmationForm = new FormGroup({
        contentValidated: new FormControl<boolean>(false, [Validators.requiredTrue]),
        documentationWithoutMail: new FormControl<boolean>(false, [Validators.requiredTrue])
    })
    transferring = false;

    constructor(@Inject(DOCUMENT) private document: Document,
                @Inject(MAT_DIALOG_DATA) readonly data: PreCheckDialogData,
                private processErrorService: ProcessErrorService,
                private externalContractsService: ExternalContractsService,
                private dialogRef: MatDialogRef<PreCheckOverlayComponent>,
                private toastService: ToastService) {
        if(!!data.customerMailAddress) {
            this.confirmationForm.controls.documentationWithoutMail.disable()
        }
    }

    ngOnInit(): void {
        this.fetchContracts();
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    startTransfer(): void {
        this.transferring = true
        this.externalContractsService.saveInitiator(this.contractsAll).pipe(
            takeUntil(this._onDestroy),
            switchMap(() => this.externalContractsService.processContracts(this.data.personManagementId)),
            catchError(err => {
                this.dialogRef.close(false)
                return this.processErrorService.handleProcessError(err);
            }),
        ).subscribe(() => {
            if (this.data.hasBrokerMandate) {
                this.toastService.queueToastMessage({
                    message: 'Die gewählten Aktionen wurden ausgeführt und die Dokumentation per E-Mail versendet',
                    notificationType: 'success'
                })
            }
            this.dialogRef.close(true);
        });
    }

    fetchContracts(): void {
        this.data.contractTiles.forEach(contractTile => {
            this.contractsAll.push(contractTile.externalContractData);

            if ((contractTile.formGroup.get('instructionId').value === INSTRUCTION_TRANSFER_TO_QUALITYPOOL) &&
                contractTile.externalContractData.validationResult.instructions.length > 0) {
                const insurer = contractTile.getSelectedInsurer();
                const contractType = contractTile.getSelectedDivision();
                this.contractsToTransfer.push({
                    number: contractTile.formGroup.get('contractNumber').value,
                    insurerName: insurer ? insurer.name : 'Versicherer fehlt',
                    contractTypeName: contractType ? contractType.label : 'Sparte fehlt'
                });
            } else if (contractTile.formGroup.get('instructionId').value === INSTRUCTION_NO_TRANSFER &&
                contractTile.externalContractData.validationResult.instructions.length > 0) {
                const insurer = contractTile.getSelectedInsurer();
                const contractType = contractTile.getSelectedDivision();
                this.contractsNotToTransfer.push({
                    number: contractTile.formGroup.get('contractNumber').value,
                    insurerName: insurer ? insurer.name : 'Versicherer fehlt',
                    contractTypeName: contractType ? contractType.label : 'Sparte fehlt'
                });
            }
        });
    }

    fetchSubmitButtonText() {
        if (this.data.hasBrokerMandate) {
            return 'Dokumentieren und Verträge übernehmen';
        } else {
            return 'Vertragsübernahme vorläufig starten';
        }
    }
}
