import { Component } from '@angular/core';

@Component({
    selector: 'tr-konzept-footer',
    templateUrl: './konzept-footer.component.html',
    styleUrls: ['./konzept-footer.component.scss'],
    host: { class: 'taures-blue-1 px-3 py-2 flex align-items-center z-2' }
})
export class KonzeptFooterComponent  {
  constructor() { }
}
