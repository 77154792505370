const DEV_ISSUER_URL = 'https://dev-sso.taures.de:8443/auth/realms/TauRes-Internal';
const PROD_ISSUER_URL = 'https://sso.taures.de/auth/realms/TauRes-Internal';

const DEV_KONZEPT_URL = 'https://dev-konzept.taures.de:8443';
const PROD_KONZEPT_URL = 'https://konzept.taures.de';

const DEV_DOCS_URL = 'https://dev-docs.taures.de:8443';
const PROD_DOCS_URL = 'https://docs.taures.de';

const devEnvironment = location.origin.includes('dev-');

export const environment = {
  production: true,
  oauth: {
    issuer: devEnvironment ? DEV_ISSUER_URL : PROD_ISSUER_URL,
    clientId: 'ci_echse_frontend',
    scope: 'openid email',
    responseType: 'code',
  },
  konzeptUrl: devEnvironment ? DEV_KONZEPT_URL : PROD_KONZEPT_URL,
  docsUrl: devEnvironment ? DEV_DOCS_URL : PROD_DOCS_URL,
};
