import {Injectable} from '@angular/core';
import {ConfirmationService} from "@taures/angular-commons";
import {EMPTY, Observable} from "rxjs";
import {PROCESS_ERRORS} from "../components/pre-check-contract-transmission-overlay/pre-check-overlay.component";
import {MatDialog} from "@angular/material/dialog";
import {
    InvalidBundleDialogData,
    InvalidBundleWarningOverlayComponent
} from "../components/invalid-bundle-warning-overlay/invalid-bundle-warning-overlay.component";

@Injectable({
    providedIn: 'root'
})
export class ProcessErrorService {

    constructor(private readonly confirmationService: ConfirmationService, private matDialogService: MatDialog) {
    }

    public handleProcessError(err: any): Observable<any> {
        let message = PROCESS_ERRORS[err.error?.messageKey ?? 'unknown'] ?? PROCESS_ERRORS['unknown'];
        if (err.error?.messageKey === 'invalidBundle') {
            const details: { insurer: string, contractNumber: string }[] = err.error?.details ?? []
            this.matDialogService.open<InvalidBundleWarningOverlayComponent, InvalidBundleDialogData, void>(
                InvalidBundleWarningOverlayComponent, {
                    panelClass: "warning-confirmation-popup",
                    width: '340px',
                    data: details
                })
            return;
        }
        const error = err.error?.messageKey === 'documentation';
        this.confirmationService.showPopup({
            message: message,
            confirmationType: error ? 'danger' : 'warning',
            confirmButtonText: 'Ok',
            showCancelButton: false
        });
        return EMPTY;
    }

}
